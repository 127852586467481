import { useStore } from 'vuex'
import { PAGES } from '~/utils/constants'

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    path,
    params: { slug },
    query,
  } = to
  const store = useStore()
  const { $sentryCaptureException } = useNuxtApp()

  if (path === PAGES.redeemPagePath) {
    return
  }

  try {
    await store.dispatch('promotion/getLandingPage', slug)
  } catch (error) {
    $sentryCaptureException(error)
    return navigateTo({
      path: `/promo/${PAGES.redeemPageSlug}`,
      query,
    })
  }
})
